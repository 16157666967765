'use client'

/* eslint-disable react/no-unescaped-entities */
import Image from 'next/image'
import Link from 'next/link'
import Button from '../components/buttons/button/button'
import { useColorSchemeContext } from '../context/colorSchemeContext/colorSchemeContext'
import { useUser } from '@auth0/nextjs-auth0/client'
import { useRouter } from 'next/navigation'
import { useEffect } from 'react'

const LoginSection = () => {
  const { colorScheme } = useColorSchemeContext()
  const logoPath = colorScheme === 'DARK' ? '/logo-white.svg' : '/logo.svg'
  sessionStorage.removeItem('suspensionSet')

  return (
    <section>
      <div className="flex flex-col items-center justify-center md:h-screen gap-6">
        <Link href="https://www.optaxe.com" className="flex items-center p-6">
          <Image src={logoPath} alt="Optaxe logo" width={200} height={200} />
        </Link>
        <div className="w-full bg-white dark:text-black rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8 flex flex-col">
            <h1 className="text-xl font-bold tracking-tight md:text-2xl text-center">
              Welcome
            </h1>

            <form
              action="/api/auth/login?returnTo=/dashboard/trade"
              className="flex justify-center"
            >
              <Button type="submit" className="w-1/3">
                Sign in
              </Button>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default function AppPage() {
  const { user, isLoading } = useUser()
  const router = useRouter()

  useEffect(() => {
    if (user && !isLoading) {
      router.push('/dashboard/trade')
    }
  }, [router, user, isLoading])

  return !user && !isLoading && <LoginSection />
}
